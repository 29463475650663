import React, { useEffect, useRef, useState } from 'react'
import CustomDropdown from "../../components/CustomDropdown"
import CustomButton from "../../components/CustomButton";
import StudentDetailsModal from "../../pages/students/StudentDetailsModal"
import Modal from '../../components/Modal';
import FloatingLabelInput from "../../components/FloatingLabelInput"
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { ACTIVE_STATUS, ADMIN__ROLE, BLOCK_CLASSES_LOCALSTORAGE_KEY, DELETED_STATUS, FILES_DOWNLOAD_LINK, FORBIDEN_MESSAGE, INACTIVE_STATUS, LOGGED_IN_USER_TOKEN_DETAILS, ONLY_PERFORMED_BY_ADMIN_MESSAGE, SCHOOLDATA_LOCALSTORAGE_KEY, SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY, SCHOOL_STREAMS_LOCALSTORAGE_KEY, SCHOOL_TOTAL_STUDENTS_COUNT } from '../../configs/constants';
import httpService from '../../services/HttpService';
import { addKeysAndValuesToArray, convertCSVToJson, extractStringsFromArray, extractStringsFromArrayWithActiveStatus, filterArrayByKeyValue, isNull, removeObjectsWithNullKey } from '../../services/Utilities';
import { INVALID_API_RESPONSE } from '../../configs/constants';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { pickSelectedObject } from '../../services/Utilities';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import SuccessfulDialog from '../../components/SuccessfulDialog';
import { Fa0 } from 'react-icons/fa6';
import CustomDropdownWithCancelIcon from '../../components/CustomDropdownWithCancelIcon';


function Students() {

  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [teachers, setTeachers] = useState([]);


  const options = ['Option 1', 'Option 2', 'Option 3'];
  const genderOptions = ['Male', 'Female'];
  const schoolAcademicBlocks = getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY);
  const [showStudentDetails, setShowStudentDetails] = useState(false);
  const [deleteStudentDetails, setDdeleteStudentDetails] = useState(false);
  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const pageCrudActions = ['AddStudent', 'EditStudent', 'DeleteStudent', 'BulkUploadStudents', 'fetchStudents', 'searchStudents', 'linkStudentToGuardian'];
  const pageDivIds = ['searchStudentView', 'crudStudentView'];
  const searchCalls = ['fetchStudents', 'searchStudents'];
  const [searchCall , setSearchCall] = useState("")
  const [selectedPageDiv, setSelectedPageDiv] = useState('');
  const [pageAction, setPageAction] = useState('');
  const [selectedStudent, setSelectedStudent] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [schoolAcademicBlocksOptions, setSchoolAcademicBlocksOptions] = useState(extractStringsFromArrayWithActiveStatus(getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY), 'blockName'))
  const [selectedSchoolAcademicBlocksOption, setSelectedSchoolAcademicBlocksOption] = useState('')
  const [selectedSchoolAcademicBlock, setSelectedSchoolAcademicBlock] = useState({});
  const [schoolClasses, setSchoolClasses] = useState([])
  const [academicBlockClasses, setAcademicBlockClasses] = useState([])
  const [academicBlockClassesOptions, setAcademicBlockClassesOptions] = useState([]);
  const [selectedBlockClassOption, setSelectedBlockClassOption] = useState('');
  const [selectedBlockClass, setSelectedBlockClass] = useState({})
  const [classStreams, setClassStreams] = useState([]);
  const [classStreamsOptions, setClassStreamsOptions] = useState([])
  const [selectedClassStreamOption, setSelectedClassStreamOption] = useState('')
  const [selectedClassStream, setSelectedClassStream] = useState({ streamId: 0 })
  const [filteredSchoolClasses, setfilteredSchoolClasses] = useState([])
  const [selectedGender, setSelectedGender] = useState('')
  const [students, setStudents] = useState([]);
  //const [searchStudentParams, setSearchStudentParams] = useState([])
  const [searchStudentParams, setSearchStudentParams] = useState([{ key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) }, { key: 'size', value: '30' }, { key: 'status', value: 'Active' }])
  const [studentAdmissionNumberForSearchParam, setStudentAdmissionNumberForSearchParam] = useState('');
  const [studentAssessmentNumberForSearchParam, setStudentAssessmentNumberForSearchParam] = useState('');
  const [importJsonData, setImportJsonData] = useState([]);
  const [finalCsvData, setFinalCsvData] = useState([])
  const [displayImportStudentsModal, setDisplayImportStudentsModal] = useState(false);
  const [schoolData, setSchoolData] = useState(getFromLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY))

  const [displayClassListFiles, setDisplayClassListFiles] = useState(false);
  const [classListFiles, setClassListFiles] = useState([])
  const [generateClassListBtnText, setGenerateClassListBtnText] = useState('Generate')
  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  const [paginationObject, setPaginationObject] = useState({ 'totalPages': 0, 'totalElements': 0, 'pageNo': 1 });
  const [paginationTotalPages, setPaginationTotalPages] = useState(0)
  const [paginationPageNo, setPaginationPageNo] = useState(0)
  const [paginationTotalElements, setPaginationTotalElements] = useState(0);
  const [guardianMsisdn, setGuardianMsisdn] = useState("")
  const [actualStreamsToDisplay , setActualStreamsToDisplay] = useState([])

  const firstName = useRef(null); //porshe macan
  const lastName = useRef(null); 
  const otherNames = useRef(null); 
  const studentAdmnNo = useRef(null); 
  const studentPassd = useRef(null); 
  const studentAssNom = useRef(null); 
  const studentDob = useRef(null); 
  const studentNemisNom = useRef(null); 
  const studentEntry = useRef(null); 

  const clearStudentFormData = () => {
    if (firstName.current) {
      firstName.current.value = '';
    }
    if (lastName.current) {
      lastName.current.value = '';
    }
    if (otherNames.current) {
      otherNames.current.value = '';
    }
    if (studentAdmnNo.current) {
      studentAdmnNo.current.value = '';
    }
    if (studentPassd.current) {
      studentPassd.current.value = '';
    }
    if (studentAssNom.current) {
      studentAssNom.current.value = '';
    }
    if (studentDob.current) {
      studentDob.current.value = '';
    }
    if (studentNemisNom.current) {
      studentNemisNom.current.value = '';
    }
    if (studentEntry.current) {
      studentEntry.current.value = '';
    }
  };

 

  function setstudentsDataToRef(student){
    firstName.current.value = student.firstName;
    lastName.current.value = student.lastName;
    otherNames.current.value = student.otherNames;
    studentAdmnNo.current.value = student.schoolAdmissionNumber;
    studentPassd.current.value = '';
    studentAssNom.current.value = '';
    studentDob.current.value = student.dateOfBirth;
    studentNemisNom.current.value = student.nemisNumber;
    studentEntry.current.value = student.studentEntry;
  }





  const handleNextPageV2 = () => {
    if (paginationPageNo < paginationTotalPages) {
      setPaginationPageNo(prevPageNo => prevPageNo + 1);
    }
  }

 

  const handlePreviousPageV2 = () => {
    if (paginationPageNo > 1) {
      setPaginationPageNo(prevPageNo => prevPageNo - 1);
    }
  }

  

  //const searchCalls = ['fetchStudents', 'searchStudents'];
  useEffect(() => {
    //load your functions here
    //setClassStreams(getFromLocalStorage(SCHOOL_STREAMS_LOCALSTORAGE_KEY));
    fetchSchoolClasses()
    //console.log('search call to set :', searchCalls[0])
    setSearchCall(searchCalls[0])
  }, []);

  useEffect(() => {
    
    console.log('searchCall chaged', searchCall)
    setPaginationPageNo(1)
  }, [searchCall]);

  

  useEffect(() => {
    if(paginationPageNo===0){
      setPaginationPageNo(1)
    }else{
      if(searchCall=== searchCalls[0]){
        fetchStudents();
      }
      if(searchCall=== searchCalls[1]){
        cleanUpSearchStudentParams() // clean up to remove undefined , then hit searchStudents
      }
    }
   
  }, [paginationPageNo]);
  
  function resetSearchStudentParamsAndFetchStudents(){
    const initialSearchParams = [{ key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) }, { key: 'size', value: '30' }, { key: 'page', value: paginationPageNo }, { key: 'status', value: 'Active' }];
    setSearchStudentParams(initialSearchParams);
    searchStudents()
  }

  function cleanUpSearchStudentParams() {
    setSearchStudentParams(prevArray => {
      // Filter out elements where 'key' or 'value' is null or undefined
      const filteredParams = prevArray.filter(data => {
        return !isNull(data.key) && !isNull(data.value);
      });
      return filteredParams;
    });
  
    // After cleaning up the search parameters, trigger the search function
    searchStudents();
  }

  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }

    setIsLoading(true)

    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)

          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)

          //rerun this , its supposed to be called once page is called
          //load your functions here
          setClassStreams(getFromLocalStorage(SCHOOL_STREAMS_LOCALSTORAGE_KEY));
          fetchSchoolClasses()
          //fetchStudents()
          setSearchCall(searchCalls[0])


        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };


  const generateClassListFilesReq = async () => {
    const payLod = {
      "schoolId": schoolData.schoolId,
      "classId": selectedBlockClass.classId,
      "recipientEmail": schoolData.email,
      "examAnalysisOption": "ClassList",
      "status": "Pending"
    };
    try {
      setIsLoading(true)
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'exam-analysis/requestClassList', payLod, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          setShowSuccessMessage(true)
          setSuccessMessage(response.header.customerMessage)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };







  const fetchClassListFiles = async () => {
    setIsLoading(true)
    setGenerateClassListBtnText('Generate')
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'exam-analysis/fetchClassDownloadFiles/' + selectedBlockClass.classId + '/ClassList', null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          setGenerateClassListBtnText('Regenerate')
          setClassListFiles(response.body)
          setDisplayClassListFiles(true)
        } else {
          if (response.header.responseCode == 404) {
            setGenerateClassListBtnText('Generate')
            //ask user to regenerate
            setDisplayClassListFiles(true)

          } else {
            setErrorMessage(response.header.customerMessage)
            setShowError(true)
          }
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };



  //paginationObject



  useEffect(() => {
    console.log(importJsonData)

    // "studentId": 2,
    //     "schoolId": 1,
    //     "academicBlockId": 1,
    //     "classId": 1,
    //     "streamId": 1,
    //"status": "Active"
    //profileImage

    const keysToAdd = [
      { key: "schoolId", value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) },
      { key: "status", value: ACTIVE_STATUS },
      { key: "academicBlockId", value: selectedSchoolAcademicBlock.academicBlockId },
      { key: "classId", value: selectedBlockClass.classId },
      { key: "streamId", value: selectedClassStream.streamId },
      { key: "streamName", value: selectedClassStream.streamName },
      { key: "profileImage", value: "/image/path" }
    ];

    const newArray = addKeysAndValuesToArray(importJsonData, keysToAdd);
    console.log('new array', newArray);
    setFinalCsvData(newArray)
  }, [importJsonData]);

  useEffect(() => {
    console.log('selectedBlockClass', selectedBlockClass)
    selectedStudent.classId = selectedBlockClass.classId;
    const actualStreamsToDisplay = classStreams.filter((data) => data.classId === selectedBlockClass.classId)
      setActualStreamsToDisplay(actualStreamsToDisplay)
      setClassStreamsOptions(extractStringsFromArray(actualStreamsToDisplay, 'streamName'))
  }, [selectedBlockClass]);



  const handleCSVInputChange = (event) => {

    validateStudentsUpload();

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      const jsonData = convertCSVToJson(csvData);

      //remove object that has grade as null
      const filteredArray = removeObjectsWithNullKey(jsonData, 'firstName');
      console.log('filtered array: ', filteredArray);

      setImportJsonData(filteredArray);
      // Reset the value of the file input to clear it
      event.target.value = "";
    };

    reader.readAsText(file);
  };


  function validateStudentsUpload() {
    if (selectedClassStream.streamId == undefined) {
      setSelectedClassStream({ streamId: 0 })
    }

    if (selectedSchoolAcademicBlock.academicBlockId == null ||
      selectedSchoolAcademicBlock.academicBlockId == undefined) {
      setDisplayImportStudentsModal(false)
      setErrorMessage("Please select Academic level.");
      setShowError(true)
      return;
    }

    if (selectedBlockClass.classId == null ||
      selectedBlockClass.classId == undefined) {
      setDisplayImportStudentsModal(false)
      setErrorMessage("Please select the class you want to save students to.");
      setShowError(true)
      return;
    }



  }



  useEffect(() => {
    console.log('studentAdmissionNumberForSearchParam --> length', studentAdmissionNumberForSearchParam.length)
    if (studentAdmissionNumberForSearchParam.length < 1) {
      console.log('should remove schoolAdmissionNumber')
      //removeObjectByKeyFromSearchString("schoolAdmissionNumber")
      setSearchStudentParams(removeObjectFromArray(searchStudentParams, "schoolAdmissionNumber"));
    } else {
      updateOrAddSearchStudentParams('schoolAdmissionNumber', { key: 'schoolAdmissionNumber', value: studentAdmissionNumberForSearchParam })
    }
  }, [studentAdmissionNumberForSearchParam]);

  useEffect(() => {
    console.log('studentAssessmentNumberForSearchParam', studentAssessmentNumberForSearchParam)
    if (studentAssessmentNumberForSearchParam.length == 0) {
      //removeObjectByKeyFromSearchString("governmentNumber")
      setSearchStudentParams(removeObjectFromArray(searchStudentParams, "governmentNumber"));
    } else {
      updateOrAddSearchStudentParams('governmentNumber', { key: 'governmentNumber', value: studentAssessmentNumberForSearchParam })
    }
  }, [studentAssessmentNumberForSearchParam]);


  const removeObjectByKeyFromSearchString = (keyToRemove) => {
    console.log('keyToRemove', keyToRemove)
    const updatedArray = searchStudentParams.filter((item) => item.id !== keyToRemove);
    console.log('updatedArray', updatedArray)
    setSearchStudentParams(updatedArray);
  };

  function removeObjectFromArray(array, keyToRemove) {
    // Find the index of the object with the provided key
    const indexToRemove = array.findIndex(obj => obj.key === keyToRemove);
    // If the object with the key is found, remove it from the array
    if (indexToRemove !== -1) {
      const newArray = [...array.slice(0, indexToRemove), ...array.slice(indexToRemove + 1)];
      return newArray;
    }
    // If the key is not found, return the original array
    return array;
  }



  const updateOrAddSearchStudentParams = (keyToUpdate, newObject) => {
    const index = searchStudentParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchStudentParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchStudentParams(prevParams => [...prevParams, newObject]);
    }
  };

  const getResultsSearchStudentString = () => {
    const searchString = searchStudentParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `onboarding/searchStudents?${searchString}`;
  };

  useEffect(() => {
    console.log('searchStudentParams', searchStudentParams)
    //setSearchCall()
  }, [searchStudentParams]);

  // useEffect(() => {
  //   if (!openSearchOptions) {
  //     fetchStudents()
  //   }
  // }, [openSearchOptions]);


  useEffect(() => {
    selectedStudent.gender = selectedGender;
  }, [selectedGender]);


  useEffect(() => {
    console.log('selectedPageDiv', selectedPageDiv)
  }, [selectedPageDiv]);




  useEffect(() => {
    setSelectedSchoolAcademicBlock(pickSelectedObject(schoolAcademicBlocks, 'blockName', selectedSchoolAcademicBlocksOption));
  }, [selectedSchoolAcademicBlocksOption]);



  useEffect(() => {
    setSelectedClassStream({})
    //filter school classes using selected academic block (setAcademicBlockClasses)
    setAcademicBlockClasses(filterArrayByKeyValue(getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY), 'academicBlockId', selectedSchoolAcademicBlock.academicBlockId))

    if (!isNull(selectedSchoolAcademicBlock.schoolAcademicBlockId)) {
      fetchAcademicBlockStreams(selectedSchoolAcademicBlock.schoolAcademicBlockId);
    }

    // //hit api to fetch classStreams
    // if (classStreams.length < 1 && !isNull(selectedSchoolAcademicBlock.schoolAcademicBlockId)) {
    //   fetchAcademicBlockStreams(selectedSchoolAcademicBlock.schoolAcademicBlockId);
    // } else {

    //   // const actualStreamsToDisplay = classStreams.filter((data) => data.schoolAcademicBlockId === selectedSchoolAcademicBlock.schoolAcademicBlockId)
    //   // setActualStreamsToDisplay(actualStreamsToDisplay)
    //   // setClassStreamsOptions(extractStringsFromArray(actualStreamsToDisplay, 'streamName'))

    // }
    selectedStudent.academicBlockId = selectedSchoolAcademicBlock.academicBlockId;

  }, [selectedSchoolAcademicBlock]);


  useEffect(() => {
    console.log('lets view classStreams fetched', classStreams)
    const actualStreamsToDisplay = filterArrayByKeyValue(classStreams, 'schoolAcademicBlockId', selectedSchoolAcademicBlock.schoolAcademicBlockId)
    //console.log('selectedSchoolAcademicBlock ((--))', selectedSchoolAcademicBlock)
    setClassStreamsOptions(extractStringsFromArray(actualStreamsToDisplay, 'streamName'))
  }, [classStreams]);




  useEffect(() => {
    setAcademicBlockClassesOptions(extractStringsFromArrayWithActiveStatus(academicBlockClasses, 'className'))
  }, [academicBlockClasses]);

  useEffect(() => {
    //set selectedClass
    const blockClass = pickSelectedObject(academicBlockClasses, 'className', selectedBlockClassOption);
    console.log('selectedBlockClass', blockClass)
    setSelectedBlockClass(blockClass)
    //set class Strems [filter by schoolAcademicBlockId]

  }, [selectedBlockClassOption]);

  // displayImportStudentsModal

  // useEffect(() => {
  //   setClassStreamsOptions(extractStringsFromArrayWithActiveStatus(classStreams,'streamName'))
  // }, [classStreamsOptions]);

  useEffect(() => {

    const pickedObject = pickSelectedObject(actualStreamsToDisplay, 'streamName', selectedClassStreamOption);
    if(isNull(pickedObject)){
      setSearchStudentParams(removeObjectFromArray(searchStudentParams, "streamId"));
    }else{
      setSelectedClassStream(pickedObject)
    }
    
  }, [selectedClassStreamOption]);

  useEffect(() => {
    if (!isNull(selectedClassStream.streamId)) {
      updateOrAddSearchStudentParams('streamId', { key: 'streamId', value: selectedClassStream.streamId })
    }
  }, [selectedClassStream]);


  
  



  useEffect(() => {
    console.log('selectedClassStream', selectedClassStream)
    selectedStudent.streamId = selectedClassStream.streamId;
    selectedStudent.streamName = selectedClassStream.streamName;
    if(isNull(selectedClassStream.streamId)){
      setSearchStudentParams(removeObjectFromArray(searchStudentParams, "streamId"));
    }
  }, [selectedClassStream]);

  const handleStudentInputChange = (e) => {
    // Update the state with the new value
    setSelectedStudent((prevStudent) => ({
      ...prevStudent,
      [e.target.name]: e.target.value,
    }));
  };


 

  const fetchStudents = async () => {
    setSearchCall(searchCalls[0]);
    setIsLoading(true)
    setPageAction(pageCrudActions[4])
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/searchStudents?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&status=Active&size=30&page=' + paginationPageNo, null, headers); //&page= +paginationObject.pageNo
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          setStudents(response.body.content);
          setPaginationTotalPages(response.body.totalPages)

          saveToLocalStorage(SCHOOL_TOTAL_STUDENTS_COUNT , response.body.totalElements)
          //'totalPages':0, 'totalElements':0,'pageNo':0
          //updatePagination('totalPages', response.body.totalPages)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };



  const searchStudents = async () => {
    //  const pageCrudActions = ['AddStudent', 'EditStudent', 'DeleteStudent', 'BulkUploadStudents' , 'fetchStudents', 'searchStudents'];
    // setPageAction(pageCrudActions[])
    //setSearchCall(searchCalls[1]);
    setIsLoading(true)
   // setPageAction(pageCrudActions[5])
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getResultsSearchStudentString()+'&page=' + paginationPageNo, null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          setStudents(response.body.content);
          //updatePagination('totalPages', response.body.totalPages)
          setPaginationTotalPages(response.body.totalPages)
          // updatePagination('pageNo', response.body.totalPages)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  const fetchSchoolClasses = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/searchBlockClass?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&status=Active', null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          setSchoolClasses(response.body.content);
          //fetchAcademicBlockStreams(selectedBlockClass schoolAcademicBlockId)
          // fetchAcademicBlockStreams(selectedSchoolAcademicBlock.schoolAcademicBlockId);
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };

  

  const fetchAcademicBlockStreams = async (schoolAcademicBlockId) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getAcademicBlockStreams/' + schoolAcademicBlockId, null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setClassStreams(response.body)
          //setSchoolStreamsArray(response.body);
          //saveToLocalStorage(SCHOOL_STREAMS_LOCALSTORAGE_KEY , response.body)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          setIsSessionExpired(true)
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  function setSelectedPageAction(student, action) {
    console.log('check this log', student)
    setSelectedStudent(student);
    setPageAction(action);
  }

  function setStudentToDelete(selectedStudent, action) {
    setConfirmationMessage('Confirm you want delete ' + selectedStudent.firstName + '?');
    setPageAction(action);
    setIsConfirmationOpen(true);
  }

  useEffect(() => {
    // Log the updated state whenever pageAction changes
    console.log("pageAction", pageAction);
    if (pageAction == pageCrudActions[0]) {//add new student
      setSelectedStudent({}) //reset student selected to create afresh
    }
    if (pageAction == pageCrudActions[2]) {
      setIsConfirmationOpen(true)
      setConfirmationMessage('Confirm you want to delete ' + selectedStudent.firstName + ', Admn: ' + selectedStudent.schoolAdmissionNumber + '?')
    }

  }, [pageAction]);

  useEffect(() => {
    //setting paramennters to help us perfoorm crud ops
    if (!isNull(selectedStudent.academicBlock)) {
      selectedStudent.academicBlockId = selectedStudent.academicBlock.academicBlockId;
      selectedStudent.classId = selectedStudent.blockClass.classId;
    }
    //selectedStudent.academicBlockId=selectedStudent.academicBlock.academicBlockId;
    // Log the updated state whenever setSelectedStudent changes
    console.log("setSelectedStudent >>>", selectedStudent);
  }, [selectedStudent]);

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
    setPageAction('');
  };

  const handleConfirmAction = () => {
    console.log('Confirmed action');
    if (pageAction == pageCrudActions[0]) { }
    if (pageAction == pageCrudActions[1]) { }
    if (pageAction == pageCrudActions[2]) { //delete student
      selectedStudent.status = DELETED_STATUS;
      selectedStudent.academicBlockId = selectedStudent.academicBlock.academicBlockId;
      selectedStudent.classId = selectedStudent.blockClass.classId;
      selectedStudent.schoolId = getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY)
      crudStudent(selectedStudent);
    }
    setPageAction('')
    setIsConfirmationOpen(false);
  };

  const handleSubmitCrudRequest = () => {
    if (pageAction == pageCrudActions[0]) { //add new
      selectedStudent.academicBlockId = selectedSchoolAcademicBlock.academicBlockId;
      selectedStudent.classId = selectedBlockClass.classId;
      selectedStudent.streamId = selectedClassStream.streamId;
      selectedStudent.streamName = selectedClassStream.streamName;
      selectedStudent.gender = selectedGender;
      selectedStudent.schoolId = getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY)
      selectedStudent.status = ACTIVE_STATUS
      selectedStudent.profileImage = "path/"
      addStudent(selectedStudent);
    }
    if (pageAction == pageCrudActions[1]) {  //edit existing
      selectedStudent.schoolId = getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY)
      console.log('about to edit this student:', selectedStudent)
      crudStudent(selectedStudent);
    }
    if (pageAction == pageCrudActions[2]) {  //delete
      selectedStudent.schoolId = getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY)
      selectedStudent.status = DELETED_STATUS
      console.log('about to edit this student:', selectedStudent)
      crudStudent(selectedStudent);
    } //delete
  }



  const importStudents = async () => {
    setIsLoading(true)
    if (isNull(selectedBlockClass.schoolId) || isNull(selectedBlockClass.classId)) {
      setErrorMessage("Ensure class has been selected")
      setShowError(true)
      return;
    }
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/import/students/' + selectedBlockClass.schoolId + '/' + selectedBlockClass.classId, finalCsvData, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSuccessMessage(response.header.customerMessage)
          setShowSuccessMessage(true)
          //fetchStudents()
          setSearchCall(searchCalls[0])
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  const linkStudentToGuardian = async (payload) => {
    setIsLoading(true)
    setPageAction('')
    try {

      const payload = [
        {
          "studentAdmissionNumber": selectedStudent.schoolAdmissionNumber,
          "phoneNumber": guardianMsisdn
        }
      ];
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/map/guardians/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), payload, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          //fetchStudents()
          setSearchCall(searchCalls[0])
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  const crudStudent = async (payload) => {
    setIsLoading(true)
    setPageAction('')
    console.log('crudStudent payload ', payload)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/student', payload, headers);
      setIsLoading(false)
      setSelectedStudent({})
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          if(searchCall=== searchCalls[0]){
            fetchStudents()
          }
          if(searchCall=== searchCalls[1]){
            //searchStudents()
            cleanUpSearchStudentParams() // clean up to remove undefined , then hit searchStudents
          }
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };

  const addStudent = async (payload) => {
    setIsLoading(true)
    setPageAction('')
    console.log('crudStudent payload ', payload)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/add/student', payload, headers);
      setIsLoading(false)
      setSelectedStudent({})
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          if(searchCall=== searchCalls[0]){
            fetchStudents()
          }
          if(searchCall=== searchCalls[1]){
            //searchStudents()
            cleanUpSearchStudentParams() // clean up to remove undefined , then hit searchStudents
          }
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };

  const openNewTab = (url) => {
    window.open(url, '_blank');
  }



  return (

    <>
      <div className="p-5 h-screen bg-gray-100 w-full overflow-y-auto  ">
        <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
          <div className='flex flex-col p-4'>
            <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
            <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
              <div className="flex flex-col py-2 ">
                <label className=" text-black text-xs">Phone number</label>
                <input
                  value={loggedInUser.mobileNumber}
                  id="phone"
                  className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                  type={"phone"}
                ></input>

              </div>
              <div className="flex flex-col py-2">
                <label className="text-black text-xs">Password</label>
                <input
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  id="password"
                  className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                  type={"password"}
                ></input>
              </div>
            </div>
            <CustomButton
              // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
              buttonStyle={
                "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
              }
              buttonText={"Login"}
              onClick={() => {
                processLoginAndAccessToken()
              }}
            />

          </div>
        </Modal>
        <Modal isVisible={displayClassListFiles} onClose={() => setDisplayClassListFiles(false)}>
          <div className='flex flex-col'>

            <div class="w-full overflow-auto rounded-lg shadow">
              <table class="w-full mb-10">
                <thead class="bg-gray-50 border-b-2 border-gray-200 w-full">
                  <tr>
                    <th class="p-3 text-sm font-semibold tracking-wide text-left">Class List</th>
                    <th class="p-3 text-sm font-semibold tracking-wide text-left">Action</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-100">

                  {classListFiles.length == 0 ?
                    (<tr className="" >
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                        <p>No Data files found</p>
                      </td>
                    </tr>)
                    :
                    classListFiles.map((classListFile, index) => (
                      <tr class={`bg-white ${isNull (classListFile.generatedDocument) && 'hidden'}`}>
                        <td class="p-3 text-xs text-gray-700 whitespace-nowrap">
                          <a onClick={() => {
                            openNewTab(FILES_DOWNLOAD_LINK + classListFile.generatedDocument)
                          }} href="#" class="font-bold text-blue-500 hover:underline">{classListFile.generatedDocument}</a>
                        </td>

                        <td class="p-3 flex space-x-2">
                          <button onClick={() => {
                            openNewTab(FILES_DOWNLOAD_LINK + classListFile.generatedDocument)
                          }} class="border p-2 rounded-lg text-sm">Download</button>
                        </td>
                      </tr>
                    ))}

                </tbody>
              </table>
            </div>

            <CustomButton
              buttonStyle={
                ` text-white my-4  text-sm px-4 py-2 cursor-pointer bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg`
              }
              buttonText={generateClassListBtnText}
              onClick={() => {
                setDisplayClassListFiles(false)
                generateClassListFilesReq()
              }}

            />
          </div>

        </Modal>
        <Modal isVisible={pageAction === pageCrudActions[6]} onClose={() => setPageAction("")}>
          <div className='flex flex-col p-6'>
            <p className=' border-b-2'>Link {selectedStudent.firstName} {selectedStudent.lastName} to guardian</p>
            <div className='grid grid-cols-2 gap-2 mt-3'>
              <div>
                <div className="relative mt-2">
                  <input type="text" name='guardianMsisdn'

                    onChange={(e) => {
                      setGuardianMsisdn(e.target.value)
                    }}
                    value={guardianMsisdn} id="guardianMsisdn" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label htmlFor="guardianMsisdn" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Guardian Phonenumber</label>
                </div>
              </div>
              <div>

                <CustomButton
                  buttonStyle={
                    "my-2 w-full text-xs text-white px-4 py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                  }
                  buttonText={"Submit"}
                  onClick={() => {
                    //crudGuardian();
                    //validateCrudGuardian()
                    linkStudentToGuardian()
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal isVisible={displayImportStudentsModal} onClose={() => setDisplayImportStudentsModal(false)}>

          <div className='flex flex-col p-6'>
            <p className=' border-b-2'>Upload your csv</p>
            <div className={`p-10 text-xs ${finalCsvData.length < 1 && 'hidden'}`}>
              <p>Successfully done. Click save to submit the extracted data </p>
            </div>
            <div className=' mt-8 w-full'>
              <input className={`w-full  border p-2  rounded-lg text-sm focus:outline-none border-gray-500 shadow-sm ${finalCsvData.length > 0 && 'hidden'}`} type="file" accept=".csv" onChange={handleCSVInputChange} />
            </div>
            <CustomButton
              buttonStyle={
                ` ${finalCsvData.length < 1 && 'hidden'} text-white my-4  text-sm px-4 py-2 cursor-pointer bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg`
              }
              buttonText={"Save"}
              onClick={() => {
                setDisplayImportStudentsModal(false)
                importStudents()
              }}

            />
          </div>

        </Modal>
        {/* Confirmation Dialog */}
        <ConfirmationDialog
          isOpen={isConfirmationOpen}
          onClose={handleCloseConfirmation}
          onConfirm={handleConfirmAction}
          message={confirmationMessage}
        />
        <LoadingDialog message={'Processing'} isOpen={isLoading} />
        <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
        <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />
        <Modal isVisible={pageAction == pageCrudActions[0] || pageAction == pageCrudActions[1] ? true : false} onClose={() => setPageAction('')}>
          <div className='flex flex-col max-h-screen p-7 overflow-y-auto'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 p-4'>
              <div className='w-full'>
                <p>Academic Block</p>
                <CustomDropdown options={schoolAcademicBlocksOptions} onOptionSelect={setSelectedSchoolAcademicBlocksOption} style={'w-full'} />
              </div>
              <div className='w-full'>
                <p>Class</p>
                <CustomDropdown options={academicBlockClassesOptions} onOptionSelect={setSelectedBlockClassOption} style={'w-full'} />
              </div>
              <div className='w-full'>
                <p>Stream (if Any)</p>
                <CustomDropdown options={classStreamsOptions} onOptionSelect={setSelectedClassStreamOption} style={'w-full'} />
              </div>

              <div className='w-full'>
                <p>Gender</p>
                <CustomDropdown options={genderOptions} onOptionSelect={setSelectedGender} style={'w-full'} />
              </div>

          
              <div>
                <div class="relative">
                  <input type="text" ref={firstName} name='firstName' onChange={handleStudentInputChange} value={selectedStudent.firstName}  id="floating_outlined" class="text-xs block px-2.5 pb-2.5 pt-4 w-full text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">First Name</label>
                </div>
              </div>

              <div>
                <div class="relative">
                  <input type="text" ref={lastName} name='lastName' onChange={handleStudentInputChange}  id="floating_outlined" class="block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Last Name</label>
                </div>
              </div>

              <div>
                <div class="relative">
                  <input type="text" ref={otherNames} name='otherNames' onChange={handleStudentInputChange} value={selectedStudent.otherNames} id="floating_outlined" class="block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Other Names</label>
                </div>
              </div>

              <div>
                <div class="relative">
                  <input type="password" ref={studentPassd} name='password' onChange={handleStudentInputChange} value={selectedStudent.password} id="floating_outlined" class="block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Student Password</label>
                </div>
              </div>

              <div>
                <div class="relative">
                  <input type="text" ref={studentAdmnNo} name='schoolAdmissionNumber' onChange={handleStudentInputChange} value={selectedStudent.schoolAdmissionNumber} id="floating_outlined" class="block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Student admission Number</label>
                </div>
              </div>

              <div>
                <div class="relative">
                  <input type="text" ref={studentAssNom} name='governmentNumber' onChange={handleStudentInputChange} value={selectedStudent.governmentNumber} id="floating_outlined" class="block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Assessment No</label>
                </div>
              </div>

              <div>
                <div class="relative">
                  <input type="text" ref={studentDob} name='dateOfBirth' onChange={handleStudentInputChange} value={selectedStudent.dateOfBirth} id="floating_outlined" class="block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">D.O.B</label>
                </div>
              </div>

              <div>
                <div class="relative">
                  <input type="text" ref={studentNemisNom} name='nemisNumber' onChange={handleStudentInputChange} value={selectedStudent.nemisNumber} id="floating_outlined" class="block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Nemis Number</label>
                </div>
              </div>

              <div>
                <div class="relative">
                  <input type="text" ref={studentEntry} name='studentEntry' onChange={handleStudentInputChange} value={selectedStudent.studentEntry} id="floating_outlined" class="block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label for="floating_outlined" class="absolute text-xs text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Student Entry</label>
                </div>
              </div>




            </div>
            <CustomButton
              buttonStyle={
                "w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Saaave"}
              onClick={() => { handleSubmitCrudRequest() }}
            />
          </div>

        </Modal>


        <div className='flex justify-end'>
          <div onClick={() => setOpenSearchOptions(!openSearchOptions)} className='flex items-center justify-end space-x-2 p-2 text-sm border text-wrap rounded-lg cursor-pointer '>
            <p>{`${openSearchOptions ? 'Close Search Filters' : 'Open Search Filters'}`}</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className={` duration-700 w-6 h-6 ${openSearchOptions && "rotate-180"}`}>
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25" />
            </svg>
          </div>
        </div>

        <div className={`flex duration-300 justify-end space-x-2  m-2 flex-col md:flex-row  ${!openSearchOptions && "hidden"}`}>

          <div></div>

          <div className='text-xs'>
            <p>Select Class Level</p>
            <CustomDropdownWithCancelIcon options={schoolAcademicBlocksOptions}
              onOptionSelect={(value) => {
                //set selected Academic Block
                const academicBlockSelected = pickSelectedObject(schoolAcademicBlocks, 'blockName', value)
                if(isNull(academicBlockSelected)){
                  setSelectedSchoolAcademicBlock(academicBlockSelected)
                  setSearchStudentParams(removeObjectFromArray(searchStudentParams, "academicBlockId"));
                }else{
                setSelectedSchoolAcademicBlocksOption(value);
                updateOrAddSearchStudentParams('academicBlockId', { key: 'academicBlockId', value: academicBlockSelected.academicBlockId })
                //setSelectedSchoolAcademicBlocksOption();  
                setSelectedPageDiv(pageDivIds[0]); //for search
                }
              }}
              style={'w-full'}
              isOptionSelected={!isNull(selectedSchoolAcademicBlocksOption)}
            />
          </div>

          <div className='text-xs'>
            <p>Select Class</p>
            <CustomDropdownWithCancelIcon options={academicBlockClassesOptions}
              onOptionSelect={(value) => {
                const selectedClass = pickSelectedObject(academicBlockClasses, 'className', value)
                if(isNull(selectedClass)){
                  setSearchStudentParams(removeObjectFromArray(searchStudentParams, "classId"));
                }else{
                  updateOrAddSearchStudentParams('classId', { key: 'classId', value: selectedClass.classId })
                  setSelectedBlockClass(selectedClass);
                }
              }}
              style={'w-full'}
              isOptionSelected={!isNull(selectedBlockClass)}
            />

          </div>


          <div className='text-xs'>
            <p>Select Stream (if Any)</p>
            {/* <CustomDropdown options={classStreamsOptions} onOptionSelect={setSelectedClassStreamOption} style={'w-full'} /> */}
            <CustomDropdownWithCancelIcon
              options={classStreamsOptions}
              onOptionSelect={(value) => {
                setSelectedClassStreamOption(value)
              }}
              style={'w-full'}
              isOptionSelected={!isNull(selectedClassStreamOption)}
            />
          </div>

          <div className='text-xs'>
            <p>Student assesment number</p>
            <div className='flex items-center rounded-md border my-2 border-gray-700 bg-light-white px-4 py-3.5'>
              <input
                type={"search"}
                placeholder="Search by assessment no."
                className={`bg-transparent text-sm w-full text-black focus:outline-none`}
                onChange={(e) => setStudentAssessmentNumberForSearchParam(e.target.value)}
                value={studentAssessmentNumberForSearchParam}
              ></input>
            </div>
          </div>
          <div className='text-xs'>
            <p>Student admission number</p>
            <div className='flex items-center rounded-md border my-2 border-gray-700 bg-light-white px-4 py-3.5'>
              <input
                type={"search"}
                placeholder="Search by admn no."
                className={`bg-transparent text-sm w-full text-black focus:outline-none`}
                value={studentAdmissionNumberForSearchParam}
                onChange={(e) => setStudentAdmissionNumberForSearchParam(e.target.value)}
              ></input>
            </div>
          </div>
          <div className='text-xs flex flex-col justify-between'>
            <p></p>
            <CustomButton
              buttonStyle={
                "my-2 text-xs text-white px-4 py-4  cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Search"}
              onClick={() => { 
                if(searchCall === searchCalls[1]){
                  cleanUpSearchStudentParams()
                }else{
                  setSearchCall(searchCalls[1])
                }
                
                //setPaginationPageNo(1)
                // if(searchCall=== searchCalls[0]){
                //   fetchStudents()
                // }
                // if(searchCall=== searchCalls[1]){
                //   //searchStudents()
                //   cleanUpSearchStudentParams() // clean up to remove undefined , then hit searchStudents
                // }
                //cleanUpSearchStudentParams() // clean up to remove undefined , then hit searchStudents
                //searchStudents()
              }}
            />
          </div>
          <div className='text-xs flex flex-col justify-between'>
            <p></p>
            <CustomButton
              buttonStyle={
                "my-2 text-xs text-white px-4 py-4  cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"View All"}
              onClick={() => { 

                if(searchCall === searchCalls[0]){
                  setSearchCall("")
                  setSearchCall(searchCalls[0])
                }else{ setSearchCall(searchCalls[0])}

               
                if(paginationPageNo===1){
                  setPaginationPageNo(0)
                }
                
              }}
            />
          </div>

          <div className='text-xs flex flex-col justify-between'>
            <p></p>
            <CustomButton
              buttonStyle={
                "my-2 text-xs text-white px-4 py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Add Student"}
              onClick={() => {
                setSelectedStudent({})
                clearStudentFormData()
                if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                  setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                  setShowError(true)
                } else {
                  setPageAction(pageCrudActions[0])
                }
              }}
            />
          </div>

          <div className='text-xs flex flex-col justify-between'>
            <p></p>
            <CustomButton
              buttonStyle={
                "my-2 text-xs text-white px-4 k py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Import from csv"}
              onClick={() => {
                if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                  setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                  setShowError(true)
                } else {
                  setDisplayImportStudentsModal(true)
                  setFinalCsvData([])
                }
              }}
            />
          </div>

          <div className='text-xs flex flex-col justify-between'>
            <p></p>
            <CustomButton
              buttonStyle={
                "my-2 text-xs text-white px-4  py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Download Class List"}
              onClick={() => {
                if (!isNull(selectedBlockClass.classId)) {
                  fetchClassListFiles()
                } else {
                  setErrorMessage("Ensure class is selected")
                  setShowError(true)
                }

                // setDisplayImportStudentsModal(true) 
                // setFinalCsvData([])

              }}
            />
          </div>

        </div>

        <div class="w-full overflow-auto rounded-lg shadow hidden md:block">
          <table class="w-full mb-10">
            <thead class="bg-gray-50 border-b-2 border-gray-200 w-full">
              <tr>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">No.</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Name</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Gender</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Entry</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Admn No.</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Assessment No.</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Nemis</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Class</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Stream</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Guardians</th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              {students.length == 0 ?
                (<tr className="" >
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <p>No Students found</p>
                  </td>
                </tr>)
                :
                students.map((student, index) => (
                  <tr class="bg-white">
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <a href="#" class="font-bold text-blue-500 hover:underline">{index + 1}</a>
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      {student.firstName} {student.otherNames} {student.lastName}
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span
                        class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-yellow-200 rounded-lg bg-opacity-50">{student.gender}</span>
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span
                        class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-yellow-200 rounded-lg bg-opacity-50">{student.studentEntry}</span>
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span
                        class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{student.schoolAdmissionNumber}</span>
                    </td>

                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{student.governmentNumber}</td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span
                        class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-blue-200 rounded-lg bg-opacity-50">{student.nemisNumber}</span>
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{student.blockClass.className}</td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{student.streamName}</td>
                    <td>
                      <ul class="flex flex-wrap justify-start space-x-4 text-xs">
                        {student.guardianList.map((guardianData, index) => (
                          <li>{guardianData.phoneNumber}</li>
                        ))}
                      </ul>
                    </td>
                    <td class="p-3 flex space-x-2">
                      <button onClick={() => {
                        setstudentsDataToRef(student);
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {
                          setSelectedPageAction(student, pageCrudActions[1])
                        }
                      }} class="border p-2 rounded-lg text-sm">Edit</button>

                      <button onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {
                          setSelectedPageAction(student, pageCrudActions[2])
                        }
                      }
                      } class="border p-2 rounded-lg text-sm">Delete</button>
                      <button onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {
                          setSelectedPageAction(student, pageCrudActions[6])
                        }
                      }
                      } class="border p-2 rounded-lg text-sm">Add Guardian</button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>



        </div>


        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4  md:hidden overflow-y-auto">

          {students.length == 0 ?

            (<div className='w-full'>
              <p>No students found</p>
            </div>) :

            students.map((student, index) => (
              <div class="bg-white space-y-3 p-4 rounded-lg shadow">
                <p class="text-blue-500 font-bold hover:underline">{student.firstName} {student.otherNames} {student.lastName}</p>
                <div class="flex items-center text-gray-500 space-x-2 text-sm">
                  <p class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-purple-200 rounded-lg bg-opacity-50">{student.blockClass.className}</p>
                  <p class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-blue-200 rounded-lg bg-opacity-50">{student.schoolAdmissionNumber}</p>
                  <p class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{student.governmentNumber}</p>
                </div>
                <div class="flex items-start text-gray-500 space-x-2 text-sm flex-col">
                  <p>Guardians:</p>
                  {/* <ul>
                    <li>21312</li>
                    <li>4322</li>
                  </ul> */}
                  <ul class="flex flex-wrap justify-start space-x-4">
                    {student.guardianList.map((guardianData, index) => (
                      <li>{guardianData.phoneNumber}</li>
                    ))}

                  </ul>

                </div>
                <div class="flex justify-end items-center space-x-2">
                  <button onClick={() => {

                    if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                      setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                      setShowError(true)
                    } else {
                      setSelectedPageAction(student, pageCrudActions[1])
                    }
                  }
                  } class="border p-2 rounded-lg text-sm">Edit</button>
                  <button onClick={() => {
                    if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                      setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                      setShowError(true)
                    } else {
                      setSelectedPageAction(student, pageCrudActions[2])
                    }
                  }

                  } class="border p-2 rounded-lg text-sm">Delete</button>
                </div>
              </div>
            ))}
        </div>



        {/* ${pageAction === pageCrudActions[5] && 'hidden'}  */}

        <div class={`bg-white p-4 border border-gray-300  ${students.length === 0 && 'hidden'}`}>
          <div class="flex justify-center items-center ">
            <a onClick={() => {
              handlePreviousPageV2()
            }} href="#" class="bg-blue-500 text-white py-2 px-4 rounded-l-lg mr-2">Back</a>

            <div class="flex text-sm">
              <p>Page {paginationPageNo} of {paginationTotalPages}</p>
            </div>
            <a onClick={() => {
              handleNextPageV2()
              //  const pageCrudActions = ['AddStudent', 'EditStudent', 'DeleteStudent', 'BulkUploadStudents' , 'fetchStudents', 'searchStudents'];
              // setPageAction(pageCrudActions[])
            }} href="#" class="bg-blue-500 text-white py-2 px-4 rounded-r-lg ml-2">Next</a>
          </div>

        </div>
      </div>



    </>
  )
}

export default Students